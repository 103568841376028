<sew-ws-header
    *ngIf="!environment.preview || (!!currentCountry && !!currentLanguage)"
    #header
    oscHeader
    baseUrl="{{ baseURL }}"
    caasBaseUrl="{{ caasBaseUrl }}"
    [apiBaseUrl]="apiBaseUrl"
    [useTracking]="true"
    [useCMP]="useCMP"
    [isPreview]="environment.preview"
    [attr.selectedLanguage]="environment.preview ? currentLanguage : null"
    [attr.countryCode]="environment.preview ? currentCountry : null"
    breadcrumbId="international_regulations"
></sew-ws-header>

<reg-landing-page [currentPage]="currentPage"></reg-landing-page>

<sew-ws-floating-buttons-api *ngIf="contactButtonUrl.length > 0" [contactApiUrl]="contactButtonUrl">
</sew-ws-floating-buttons-api>
<sew-ws-footer-api
    *ngIf="language.length > 0 && countryCode.length > 0"
    [caasBaseUrl]="caasBaseUrl"
    [countryCode]="countryCode"
    [language]="language.toUpperCase()"
    [displayNewestBlogPost]="displayFooterBlogPost"
>
    <a
        *ngFor="let link of footerLinks"
        slot="additional-legal-links"
        [href]="link.url"
        [target]="link.target"
    >
        {{ link.text }}</a
    >
</sew-ws-footer-api>
