import { OS_API_TOKEN_ENDPOINT } from '../lib/helpers/public-helpers';

export const DEVELOPMENT_ENVIRONMENT = {
    production: false,
    useCMP: true,
    useMockServer: false,
    useMopinion: true,
    defaultLang: 'en_US',
    cms: 'debrucmsd',
    baseURLs: {
        api: {
            international: 'https://api.sew-d.com',
            translation: '/os/api/service/translate',
            token: OS_API_TOKEN_ENDPOINT,
            workbench: 'https://api.sew-d.com/workbenchwebapi/api',
            os: '/os',
        },
    },
    caas: {
        baseUrl: 'https://caas.sew-d.com',
    },
    apiGateway: {
        dmz: {
            baseUrl: 'https://apigateway-external.sew-d.com/gateway',
        },
    },
};
