import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { delay, distinctUntilChanged, expand, map, Observable, ReplaySubject, tap } from 'rxjs';

export const TOKEN_BASE_HREF = new InjectionToken<string>('OS_API:TOKEN_BASE_HREF');

/**
 * Service for fetching a x-csrf token for the user
 */
@Injectable({
    providedIn: null,
})
export class OsApiAuthService {
    private pToken = new ReplaySubject<string>(1);

    public constructor(private http: HttpClient, @Inject(TOKEN_BASE_HREF) private tokenURL: string) {}

    /**
     * Fetch the token from the server on initial app load
     * and later after the refresh runs out
     */
    public fetchToken() {
        // @TODO: Error handling
        return this.fetchTokenResponse().pipe(
            expand((response) => this.fetchTokenResponse().pipe(delay((response.body ?? 1800) * 900))),
            map((response) => response.headers.get('x-csrf-token') as string),
            tap((token) => this.setToken(token))
        );
    }

    private fetchTokenResponse() {
        return this.http.get<number>(this.tokenURL, {
            headers: new HttpHeaders({
                'x-csrf-token': 'fetch',
            }),
            withCredentials: true,
            observe: 'response',
        });
    }

    public get token(): Observable<string> {
        return this.pToken.asObservable().pipe(distinctUntilChanged());
    }

    private setToken(newToken: string) {
        this.pToken.next(newToken);
    }
}
