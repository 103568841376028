import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { OsApiAuthRealms } from './os-api-auth.interface';
import { OsApiAuthService } from './os-api-auth.service';

export const API_AUTH_REALMS = new InjectionToken<OsApiAuthRealms>('OS_API:API_BASE_URL');

/**
 * Interceptor to handle requests failing with unauthorized error by retrying with a new token.
 */
@Injectable({
    providedIn: null,
})
export class OsApiRetryUnauthorizedInterceptor implements HttpInterceptor {
    /**
     * http methods, which are relevant to this specific type of error
     */
    private relevantHttpMethods = ['PUT', 'POST', 'PATCH', 'DELETE'];

    public constructor(private osApiAuthService: OsApiAuthService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 403 && this.relevantHttpMethods.includes(req.method)) {
                        return this.osApiAuthService.fetchToken().pipe(switchMap(() => next.handle(req)));
                    }
                }

                return throwError(error);
            })
        );
    }
}
