import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HeaderLoginService } from '../public-services';
import { OsApiAuthRealm, OsApiAuthRealms } from './os-api-auth.interface';
import { OsApiAuthService } from './os-api-auth.service';

export const API_AUTH_REALMS = new InjectionToken<OsApiAuthRealms>('OS_API:API_BASE_URL');

/**
 * Interceptor to handle authentication tokens/headers for certain HTTP requests
 */
@Injectable({
    providedIn: null,
})
export class OsApiAuthInterceptor implements HttpInterceptor {
    private authToken = '';

    public constructor(
        @Inject(API_AUTH_REALMS) private apiRealms: OsApiAuthRealms,
        private authService: OsApiAuthService,
        private loginService: HeaderLoginService
    ) {
        this.authService.token.subscribe((token) => (this.authToken = token));
    }

    public intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        const apiRealm = this.apiRealms.find((realm) => req.url.startsWith(realm.path));

        if (!apiRealm) {
            return next.handle(req);
        }

        return this.realmIntercept(apiRealm, req, next);
    }

    private realmIntercept<T>(
        realm: OsApiAuthRealm,
        req: HttpRequest<T>,
        next: HttpHandler
    ): Observable<HttpEvent<T>> {
        const newRequest = {
            url: `${realm.apiUrl}${req.url.replace(realm.path, '')}`,
            withCredentials: realm.withCredentials,
            setHeaders: realm.headers,
        };

        const cloneRequestWithToken = (request: HttpRequest<unknown>) =>
            request.clone({
                ...newRequest,
                setHeaders: {
                    ...newRequest.setHeaders,
                    ...(realm.useXCsrfToken && { 'x-csrf-token': this.authToken }),
                },
            });
        const getRequestClone = () => {
            const isGet = req.method === 'GET';
            const handle = isGet
                ? next.handle(req.clone(newRequest))
                : next.handle(cloneRequestWithToken(req));

            // TODO: implement generic solution for basket
            if (!realm.retriggerAfterLoginChange || newRequest.url.includes('/basket')) {
                return handle;
            }

            return (isGet && realm.withCredentials) ||
                (!isGet && (realm.withCredentials || realm.useXCsrfToken))
                ? this.loginService.loggedIn.pipe(switchMap(() => handle))
                : handle;
        };

        return getRequestClone();
    }
}
